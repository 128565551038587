
//Core
import 'Core/main';

//SCSS
import 'scss/PacGratuite/app.scss';

//Extranet JS
import "App/BundledOffer/faq"
import "App/Simulation/residential"
import "App/Divers/dialog"

//Vte Tools
import "vteTools/precarity-select"
import "vteTools/Simulation/address-conditional-fields"
import "vteTools/Simulation/pro-conditional-fields"
import "vteTools/tooltipsVte"

import "Iframe/Iframe/iframe"
